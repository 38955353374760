import login from "./login/ru.json";
import registration from "./registration/ru.json";
import resetPassword from "./reset-password/ru.json";
import availability from "./availability/ru.json";
import pricesDiscounts from "./prices-discounts/ru.json";
import content from "./content/ru.json";
import productList from "./product-list/ru.json";
import masterList from "./master-list/ru.json";
import reviewsAndRating from "./reviews-and-rating/ru.json";
import searchPosition from "./search-position/ru.json";
import plannedReports from "./planned-reports/ru.json";
import filterGroups from "./filter-groups/ru.json";
import dataCopy from "./data-copy/ru.json";
import products from "./products/ru.json";
import profile from "./profile/ru.json";
import serpProducts from "./serp-products/ru.json";
import sales from "./sales/ru.json";
import users from "./users/ru.json";
import brandAliases from "./brand-aliases/en.json";

export const pages = {
  pages: {
    ...login,
    ...registration,
    ...resetPassword,
    ...availability,
    ...pricesDiscounts,
    ...content,
    ...productList,
    ...masterList,
    ...reviewsAndRating,
    ...searchPosition,
    ...plannedReports,
    ...filterGroups,
    ...dataCopy,
    ...products,
    ...profile,
    ...serpProducts,
    ...sales,
    ...users,
    ...brandAliases,
  },
};
