import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { masterListFilters } from "@/api/filters";
import { useAppDispatch } from "@/store";
import { setFiltersData } from "@/store/filters";
import { FilterIcons } from "@/consts/filter-icons";

import type { TRequest, TResponse } from "@/api/filters/types";

const useMasterListFilters = (request?: TRequest, isDispatch?: boolean) => {
  const dispatch = useAppDispatch();

  const { getMasterListFilters } = masterListFilters;
  const {
    data: response,
    loading,
    error,
  } = useQuery<TResponse>(getMasterListFilters, {
    variables: {
      clientId: request?.clientId || null,
      ids: request?.attributes || [
        "retailer",
        "spid",
        "skuId",
        "msrp",
        "productName",
        "eanGtin",
        "brandName",
        "category",
        "lastEditor",
        "showUser",
        "rawProductName",
        "rawBrandName",
        "rawCategory",
      ],
    },
    fetchPolicy: "no-cache",
  });

  const data = useMemo(
    () =>
      response
        ? {
            filters: response.filters.map((filter) => ({
              ...filter,
              icon: FilterIcons[filter.id],
            })),
          }
        : undefined,
    [response]
  );

  useEffect(() => {
    if (isDispatch) dispatch(setFiltersData(data));
  }, [data]);

  return {
    data,
    loading,
    error,
  };
};

export { useMasterListFilters };
