import { gql, useMutation } from "@apollo/client";

export type TRequest = {
  name: string;
};

export type TResponse = {
  id: number;
  name: string;
};

const useCreateCustomFieldsLazy = () => {
  const CREATE_CUSTOM_FIELDS_LAZY = gql`
    mutation CreateCustomField($name: String!) {
      createCustomField(name: $name) {
        id
        name
      }
    }
  `;

  const [createCustomFieldsLazy, { data, error, loading }] = useMutation<
    TResponse,
    TRequest
  >(CREATE_CUSTOM_FIELDS_LAZY);

  return {
    createCustomFieldsLazy,
    data,
    error,
    loading,
  };
};

export { useCreateCustomFieldsLazy };
