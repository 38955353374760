import { gql, useQuery } from "@apollo/client";

import type { TCustomField } from "@/modules/product-list/types/TCustomField";

export type TResponse = {
  getCustomFields: {
    fields: TCustomField[];
  };
};

const useGetCustomFields = (clientId?: number) => {
  const GET_CUSTOM_FIELDS = gql`
    query GetCustomFields {
      getCustomFields ${clientId ? `(clientId: ${clientId})` : ""}{
        fields {
          id
          name
        }
      }
    }
  `;
  const { loading, error, data } = useQuery<TResponse>(GET_CUSTOM_FIELDS, {
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    error,
    data,
  };
};

export { useGetCustomFields };
