import { gql, useMutation } from "@apollo/client";

type TRequest = {
  clientId: number;
};

const useProductListEdit = (
  rowList: string[],
  tableType: "product-list" | "mapping-list"
) => {
  let mutateFn;

  if (rowList.length) {
    if (tableType === "mapping-list") {
      let mutationString = "mutation UpdateProductSku ($skuId: String!) {";

      rowList.forEach((item, index) => {
        const [spid, retailer] = item.split("--");
        mutationString += `
          updateRow${index}: updateProductSku (
            retailer: "${retailer}"
            spid: "${spid}"
            skuId: $skuId
          ) {
            spid
          }
        `;
      });
      mutationString += "}";
      mutateFn = gql(mutationString);
    } else {
      let mutationString = `mutation UpdateProductInfo (
        $productName: String,
        $msrp: String,
        $brandName: String,
        $category: String,
        $eanGtin: String,
        $customValues: [CustomValueInput]
    ){`;

      rowList.forEach((item, index) => {
        mutationString += `
          updateRow${index}: updateProductInfo (
            skuId: "${item}"
            productInfo: {
              productName: $productName,
              msrp: $msrp,
              brandName: $brandName,
              category: $category,
              eanGtin: $eanGtin,
              customValues: $customValues
            }
          ) {
            skuId
          }
        `;
      });
      mutationString += "}";
      mutateFn = gql(mutationString);
    }
  } else {
    mutateFn = gql`
      mutation ($clientId: BigInt, $date: Date!, $filters: [FilterInput!]) {
        removeProductList(
          forDate: $date
          clientId: $clientId
          filters: $filters
        )
      }
    `;
  }

  const [mutateFunction, { data, loading, error }] =
    useMutation<TRequest>(mutateFn);

  return {
    mutateFunction,
    data,
    loading,
    error,
  };
};

export { useProductListEdit };
