import React, { useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";

import Calendar from "@/icons/filters/calendar.svg";
import NextMonth from "@/icons/filters/next-month.svg";
import PrevMonth from "@/icons/filters/prev-month.svg";
import Arrow from "@/icons/filters/arrow.svg";

import "./page-filter-calendar-single.scss";

type TDay = {
  day: number;
  month: number;
  year: number;
  type: "active" | "in-active";
};

const chunkArray = (array: TDay[], chunkSize: number) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }

  return chunkedArray;
};

const monthOptions = [
  {
    label: "modules.atoms.filter-calendar.jan",
    value: 0,
  },
  {
    label: "modules.atoms.filter-calendar.feb",
    value: 1,
  },
  {
    label: "modules.atoms.filter-calendar.mar",
    value: 2,
  },
  {
    label: "modules.atoms.filter-calendar.apr",
    value: 3,
  },
  {
    label: "modules.atoms.filter-calendar.may",
    value: 4,
  },
  {
    label: "modules.atoms.filter-calendar.jun",
    value: 5,
  },
  {
    label: "modules.atoms.filter-calendar.jul",
    value: 6,
  },
  {
    label: "modules.atoms.filter-calendar.aug",
    value: 7,
  },
  {
    label: "modules.atoms.filter-calendar.sep",
    value: 8,
  },
  {
    label: "modules.atoms.filter-calendar.okt",
    value: 9,
  },
  {
    label: "modules.atoms.filter-calendar.nov",
    value: 10,
  },
  {
    label: "modules.atoms.filter-calendar.dec",
    value: 11,
  },
];

type PageFilterCalendarSingleProps = {
  initialDate?: dayjs.Dayjs | null;
  onSubmit: (date: dayjs.Dayjs | null) => void;
  isFullDate?: boolean;
};

const PageFilterCalendarSingle = ({
  initialDate,
  onSubmit,
  isFullDate,
}: PageFilterCalendarSingleProps) => {
  const { t, i18n } = useTranslation();

  const currentDate = dayjs();
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    initialDate || currentDate
  );
  const [currentMonth, setCurrentMonth] = useState(
    (initialDate || currentDate).month()
  );
  const [currentYear, setCurrentYear] = useState(
    (initialDate || currentDate).year()
  );

  const ref = useRef<HTMLDivElement>(null);

  const daysInCurrentMonth = useMemo(() => {
    const days: TDay[] = [];

    const selectedDate = dayjs().year(currentYear).month(currentMonth);
    const startOfMonth = selectedDate.startOf("month");
    const endOfMonth = selectedDate.endOf("month");

    const prevMonthDate =
      currentMonth > 0
        ? dayjs()
            .year(currentYear)
            .month(currentMonth - 1)
        : dayjs()
            .year(currentYear - 1)
            .month(11);

    const nextMonthDate =
      currentMonth < 11
        ? dayjs()
            .year(currentYear)
            .month(currentMonth + 1)
        : dayjs()
            .year(currentYear + 1)
            .month(0);

    const startWeekday = (startOfMonth.day() + 6) % 7;
    for (let i = startWeekday - 1; i >= 0; i -= 1) {
      days.push({
        day: prevMonthDate.endOf("month").date() - i,
        month: prevMonthDate.month(),
        year: prevMonthDate.year(),
        type: "in-active",
      });
    }

    for (let i = startOfMonth.date(); i <= endOfMonth.date(); i += 1) {
      days.push({
        day: i,
        month: currentMonth,
        year: currentYear,
        type: "active",
      });
    }

    // Следующий месяц - заполняем оставшиеся дни недели после конца текущего месяца
    for (let i = 1; i <= 6 - endOfMonth.day(); i += 1) {
      days.push({
        day: i,
        month: nextMonthDate.month(),
        year: nextMonthDate.year(),
        type: "in-active",
      });
    }

    return days;
  }, [currentMonth, currentYear]);
  const yearOptions = useMemo(() => {
    const result = [];
    for (let i = 1980; i <= currentYear; i += 1) {
      result.push({
        value: i,
        label: i,
      });
    }

    return result;
  }, []);

  const handleShowCalendar = () => setShowCalendar((prevState) => !prevState);

  const getCellClass = (item: TDay) => {
    let current = null;
    const dayItem = dayjs().year(item.year).month(item.month).date(item.day);

    if (dayItem.format("DD-MM-YYYY") === currentDate.format("DD-MM-YYYY")) {
      current = "filter-calendar-single__dropdown-selected-current";
    }

    if (item.type === "in-active") {
      return [
        "filter-calendar-single__dropdown-selected-disabled",
        current,
      ].join(" ");
    }

    if (dayItem.format("DD-MM-YYYY") === date?.format("DD-MM-YYYY")) {
      return ["filter-calendar-single__dropdown-selected", current].join(" ");
    }

    if (dayItem.format("DD-MM-YYYY") === date?.format("DD-MM-YYYY")) {
      return [
        "filter-calendar-single__dropdown-selected-disabled",
        current,
      ].join(" ");
    }

    return ["filter-calendar-single__dropdown-cell", current].join(" ");
  };

  const handleClick = (item: TDay) => () => {
    if (item.type === "in-active") {
      return undefined;
    }

    const dayItem = dayjs().year(item.year).month(item.month).date(item.day);
    setDate(dayItem);
  };

  const handleMonthSelect = (item: number) => {
    setCurrentMonth(item);
  };

  const handleYearSelect = (item: number) => {
    setCurrentYear(item);
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear((prevState) => prevState + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prevState) => prevState + 1);
    }
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear((prevState) => prevState - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prevState) => prevState - 1);
    }
  };

  // const handleSelectToday = () => {
  //   setDate(currentDate);
  //   setCurrentYear(currentDate.year());
  //   setCurrentMonth(currentDate.month());
  // };

  const handleSubmit = () => {
    onSubmit(date);
    setShowCalendar(false);
  };

  const handleReset = () => {
    setDate(initialDate || currentDate);
    setCurrentMonth((initialDate || currentDate).month());
    setCurrentYear((initialDate || currentDate).year());
    handleSubmit();
  };

  const formattedDate = useMemo(
    () =>
      date?.locale(i18n.language)?.format(isFullDate ? "DD MMMM" : "DD MMM") ||
      "",
    [i18n.language, date]
  );

  return (
    <div className="filter-calendar-single">
      <div
        onClick={handleShowCalendar}
        className={`filter-calendar-single__input${
          showCalendar ? " filter-calendar-single__input--open" : ""
        }`}
      >
        <span>{formattedDate}</span>
        <Calendar />
      </div>
      {showCalendar && (
        <>
          <div
            className="filter-calendar-single__dropdown-bg"
            onClick={handleShowCalendar}
          />
          <div ref={ref} className="filter-calendar-single__dropdown">
            {/* <Button
              className="filter-calendar-single__dropdown__item"
              onClick={handleSelectToday}
              type="primary"
            >
              {t("modules.atoms.filter-calendar.today")}
            </Button> */}
            <div className="filter-calendar-single__dropdown-header">
              <div className="filter-calendar-single__dropdown-header-selectors">
                <Select
                  onSelect={handleMonthSelect}
                  suffixIcon={<Arrow />}
                  value={currentMonth}
                  options={monthOptions.map(({ label, ...option }) => ({
                    ...option,
                    label: t(label),
                  }))}
                />
                <Select
                  onSelect={handleYearSelect}
                  options={yearOptions}
                  suffixIcon={<Arrow />}
                  value={currentYear}
                />
              </div>
              <div className="filter-calendar-single__dropdown-header-switcher">
                <button onClick={handlePrevMonth} type="button">
                  <PrevMonth />
                </button>
                <button onClick={handleNextMonth} type="button">
                  <NextMonth />
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>{t("modules.atoms.filter-calendar.mo")}</th>
                  <th>{t("modules.atoms.filter-calendar.tu")}</th>
                  <th>{t("modules.atoms.filter-calendar.we")}</th>
                  <th>{t("modules.atoms.filter-calendar.th")}</th>
                  <th>{t("modules.atoms.filter-calendar.fr")}</th>
                  <th>{t("modules.atoms.filter-calendar.sa")}</th>
                  <th>{t("modules.atoms.filter-calendar.su")}</th>
                </tr>
              </thead>
              <tbody>
                {chunkArray(daysInCurrentMonth, 7).map((array, arrayIndex) => (
                  <tr key={`${arrayIndex}`}>
                    {array.map((item, index) => (
                      <td key={`${arrayIndex}-${index}`}>
                        <button
                          className={getCellClass(item)}
                          onClick={handleClick(item)}
                          type="button"
                        >
                          {item.day}
                        </button>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="filter-calendar-single__dropdown-buttons">
              <Button onClick={handleReset}>
                {t("modules.atoms.filter-calendar.cancel")}
              </Button>
              <Button onClick={handleSubmit} type="primary">
                {t("modules.atoms.filter-calendar.apply")}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { PageFilterCalendarSingle };
