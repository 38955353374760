import { gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TState } from "@/store/types";
import type { TProduct } from "@/modules/shared/types";

export type TResponse<T extends Partial<TProduct>> = {
  getProductsSku: {
    productsSku: T[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

type TPagination = {
  limit: number;
  page: number;
};

type TSort = {
  by: string;
  order: string;
};

const useProductsSku = (
  pagination: TPagination,
  sort: TSort,
  productParams: (
    | keyof TProduct
    | "updatedAt"
    | "msrp"
    | "eanGtin"
    | "brandName"
    | "productName"
  )[],
  skip = false
) => {
  const { dynamicFilters, searchText } = useSelector(
    (state: TState) => state.filters
  );

  const queryFn = gql`
    query GetProductsSku(
      $pagination: PaginationInput!
      $filters: [FilterInput!]
      $sort: MasterListSortInput!
    ) {
      products: getProductsSku(
        pagination: $pagination
        filters: $filters
        sort: $sort
      ) {
        products {
          spid
          ${productParams.join("\n")}
        }
        pagination {
          limit
          page
          total
          pages
        }
      }
    }
  `;
  const { loading, error, data } = useQuery<any>(queryFn, {
    // const { loading, error, data } = useQuery<TResponse>(queryFn, {
    variables: {
      filters: getFiltersFromDynamicFilters(dynamicFilters),
      pagination,
      sort,
      searchText,
      searchFields: productParams,
    },
    fetchPolicy: "no-cache",
    skip,
  });

  return {
    loading,
    error,
    data,
  };
};

export { useProductsSku };
