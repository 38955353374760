import { gql, useMutation } from "@apollo/client";

export type TRequest = {
  id: number;
};

export type TResponse = {
  createSheet: boolean;
};

const useDeleteCustomFieldsLazy = () => {
  const DELETE_CUSTOM_FIELDS_LAZY = gql`
    mutation removeCustomField($id: Int) {
      removeCustomField(id: $id)
    }
  `;

  const [deleteCustomFieldsLazy, { data, error, loading }] = useMutation<
    TResponse,
    TRequest
  >(DELETE_CUSTOM_FIELDS_LAZY);

  return {
    deleteCustomFieldsLazy,
    data,
    error,
    loading,
  };
};

export { useDeleteCustomFieldsLazy };
