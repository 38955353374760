import { gql, useLazyQuery } from "@apollo/client";

const useExportProductsInfoTemplate = () => {
  const EXPORT_PRODUCTS_INFO_TEMPLATE = gql`
    query ProductInfoImportTemplate {
      productInfoImportTemplate
    }
  `;

  const [getExportProductsInfoTemplate, { loading, error, data }] =
    useLazyQuery<any>(EXPORT_PRODUCTS_INFO_TEMPLATE, {
      variables: {},
      fetchPolicy: "no-cache",
    });

  return {
    getExportProductsInfoTemplate,
    loading,
    error,
    data,
  };
};

export { useExportProductsInfoTemplate };
