import { gql, useQuery } from "@apollo/client";

export type TResponse = {
  getMasterListSuggest: {
    values: {
      id: string;
      value: string;
    }[];
  };
};

const useMasterListSuggest = (
  id: string,
  text: string,
  top: number,
  skip: boolean | undefined,
  clientId: number | null
) => {
  const GET_SUGGEST = gql`
    query getMasterListSuggest(
      $id: String!
      $text: String!
      $top: Int
      $clientId: BigInt
    ) {
      getMasterListSuggest(
        id: $id
        text: $text
        top: $top
        clientId: $clientId
      ) {
        values {
          id
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<TResponse>(GET_SUGGEST, {
    variables: {
      clientId,
      id,
      text,
      top,
    },
    skip: !skip || !text,
    fetchPolicy: "no-cache",
  });

  return { loading, error, data };
};

export { useMasterListSuggest };
