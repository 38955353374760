import { gql, useLazyQuery } from "@apollo/client";

const useExportProductsSkuTemplate = () => {
  const EXPORT_PRODUCTS_SKU_TEMPLATE = gql`
    query ProductSkuImportTemplate {
      productSkuImportTemplate
    }
  `;

  const [getExportProductsSkuTemplate, { loading, error, data }] =
    useLazyQuery<any>(EXPORT_PRODUCTS_SKU_TEMPLATE, {
      variables: {},
      fetchPolicy: "no-cache",
    });

  return {
    getExportProductsSkuTemplate,
    loading,
    error,
    data,
  };
};

export { useExportProductsSkuTemplate };
