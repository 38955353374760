import shared from "./shared/en.json";
import filterSelect from "./filter-select/en.json";
import filterCalendar from "./filter-calendar/en.json";
import filterCalendarMonthly from "./filter-calendar-monthly/en.json";
import imageslistDrawer from "./images-list-drawer/en.json";

export const atoms = {
  shared,
  "filter-select": filterSelect,
  "filter-calendar": filterCalendar,
  "filter-calendar-monthly": filterCalendarMonthly,
  "images-list-drawer": imageslistDrawer,
};
