import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

import { useAppDispatch } from "@/store";
import { setUseLists } from "@/store/product-list";

export type TResponse = {
  productsInfo: {
    __typename: string;
    id: number;
    mpn: string;
    category: string;
    msrp: number;
    brandName: string;
    eanGtin: string;
    productName: string;
    updatedAt: string;
    createdAt: string;
  }[];
};

const useProductListProducts = () => {
  const dispatch = useAppDispatch();
  const queryFn = gql`
    query GetProductsInfo {
      productsInfo: getProductsInfo {
        products {
          category
          msrp
          skuId
          eanGtin
          mpn
          brandName
          productName
          updatedAt
          createdAt
        }
        pagination {
          limit
          page
          total
          pages
        }
      }
    }
  `;

  const { loading, error, data, refetch } = useQuery<TResponse>(queryFn);

  useEffect(() => {
    dispatch(setUseLists(!!data?.productsInfo?.length));
  }, [data?.productsInfo]);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

export { useProductListProducts };
