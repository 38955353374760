import { gql, useMutation } from "@apollo/client";

const useProductCreate = (tableType: "product-list" | "mapping-list") => {
  let mutateFn;

  if (tableType === "mapping-list") {
    mutateFn = gql(`mutation CreateProductSku(
      $retailer: String!
      $spid: String!
      $skuId: String!
    ){
      createProductSku(
        retailer: $retailer
        spid: $spid
        skuId: $skuId
      ){
        retailer
        spid,
        skuId
        createdAt
        updatedAt
      }
    }`);
  } else {
    mutateFn = gql(`mutation CreateProductInfo(
      $skuId: String!
      $productName: String
      $msrp: String
      $brandName: String
      $category: String
      $eanGtin: String
      $customValues: [CustomValueInput]
    ){
      createProductInfo(
        skuId: $skuId,
        productInfo: {
          productName: $productName
          msrp: $msrp
          brandName: $brandName
          category: $category
          eanGtin: $eanGtin
          customValues: $customValues
        }
	    ){
        productName
        brandName
        msrp
        category
        skuId
        eanGtin
        updatedAt
        createdAt
      }
    }`);
  }

  const [mutateFunction, { data, loading, error }] = useMutation(mutateFn);

  return {
    mutateFunction,
    data,
    loading,
    error,
  };
};

export { useProductCreate };
