import { gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import {
  getFiltersFromDynamicFilters,
  nullStringToValueInFilters,
} from "@/modules/shared/utils";

import type { TState } from "@/store/types";
import type { TProduct } from "@/modules/shared/types";

export type TResponse<T extends Partial<TProduct>> = {
  getProductsInfo: {
    productsSku: T[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

type TPagination = {
  limit: number;
  page: number;
};

type TSort = {
  by: string;
  order: string;
};

const useProductsInfo = (
  pagination: TPagination,
  sort: TSort,
  productParams: (
    | keyof TProduct
    | "updatedAt"
    | "msrp"
    | "eanGtin"
    | "brandName"
    | "productName"
    | any
  )[],
  skip = false
) => {
  const { dynamicFilters, searchText } = useSelector(
    (state: TState) => state.filters
  );

  const queryFn = gql`
    query GetProductsInfo(
      $pagination: PaginationInput!
      $filters: [FilterInput!]
      $sort: MasterListSortInput!
    ) {
      products: getProductsInfo(
        pagination: $pagination
        filters: $filters
        sort: $sort
      ) {
        products {
          ${productParams
            .filter((param) => !param.includes("custom_field_"))
            .join("\n")}
          customValues{
            id
            value
          }
        }
        pagination {
          limit
          page
          total
          pages
        }
      }
    }
  `;
  const { loading, error, data } = useQuery<any>(queryFn, {
    // const { loading, error, data } = useQuery<TResponse>(queryFn, {
    variables: {
      filters: getFiltersFromDynamicFilters(
        nullStringToValueInFilters(dynamicFilters)
      ),
      pagination,
      sort,
      searchText,
      searchFields: productParams.filter(
        (param) => !param.includes("custom_field_")
      ),
    },
    fetchPolicy: "no-cache",
    skip,
  });

  return {
    loading,
    error,
    data,
  };
};

export { useProductsInfo };
