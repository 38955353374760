import { gql, useMutation } from "@apollo/client";

import type { TSheet } from "@/hooks/sheets/types";

export type TRequest = {
  id: number;
  name: string;
};

export type TResponse = {
  createSheet: TSheet;
};

const useEditCustomFieldsLazy = () => {
  const EDIT_CUSTOM_FIELDS_LAZY = gql`
    mutation UpdateCustomField($id: Int, $name: String!) {
      updateCustomField(id: $id, name: $name) {
        id
        name
      }
    }
  `;

  const [editCustomFieldsLazy, { data, error, loading }] = useMutation<
    TResponse,
    TRequest
  >(EDIT_CUSTOM_FIELDS_LAZY);

  return {
    editCustomFieldsLazy,
    data,
    error,
    loading,
  };
};

export { useEditCustomFieldsLazy };
