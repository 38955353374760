import { createSlice } from "@reduxjs/toolkit";

import { reducers } from "@/store/filters/reducers";

import type { TFilterState } from "@/store/filters/types";

const initialState: TFilterState = {
  date: {
    startDate: null,
    endDate: null,
  },
  dynamicFilters: {},
  filtersData: undefined,
  searchText: undefined,
  isPopup: false,
  popupDate: {
    startDate: null,
    endDate: null,
  },
  tableSort: {
    by: "THE_DATE",
    order: "DESC",
  },
  popupDynamicFilters: {},
  popupFiltersData: undefined,
  popupSearchText: undefined,
};

export const filtersSlice = createSlice({
  name: "Filters",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const filtersReducer = filtersSlice.reducer;

export type { TFilterState };

export { filtersReducer };

export const {
  reset,
  setIsPopup,
  setDateFilter,
  setDynamicFilters,
  setDynamicFilter,
  removeDynamicFilter,
  resetDynamic,
  setFiltersData,
  setSearchText,
  setPopupDateFilter,
  setPopupDynamicFilters,
  setPopupDynamicFilter,
  removePopupDynamicFilter,
  resetPopupDynamic,
  setPopupFiltersData,
  setPopupSearchText,
  setTableSort,
} = filtersSlice.actions;
