import { useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/ru";
// import "dayjs/locale/en";
import { useTranslation } from "react-i18next";

import type { FC } from "react";

const GlobalFunctionality: FC = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "";

    axios.interceptors.request.use(
      (config) =>
        // TODO
        config,
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // if any api call says 401 Unauthorized, then drop the token and redirect to the login page
        if (error.response?.status === 401 || error.response?.status === 403) {
          localStorage.removeItem("token");
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    dayjs.locale("ru");
  }, [i18n.language]);

  return null;
};

export default GlobalFunctionality;
