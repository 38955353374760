import { gql, useMutation } from "@apollo/client";

export enum EUploadFile {
  IMPORT_PRODUCT_INFO = "IMPORT_PRODUCT_INFO",
  IMPORT_PRODUCT_SKU = "IMPORT_PRODUCT_SKU",
}

export type TRequest = {
  file: FormData;
  importType: EUploadFile;
};

const useProductListUploadFile = () => {
  const mutateFn = gql`
    mutation ($file: UploadFile!, $importType: ImportTypeEnum!) {
      uploadFile(file: $file, importType: $importType)
    }
  `;

  const [mutateFunction, { data, loading, error }] =
    useMutation<TRequest>(mutateFn);

  return {
    mutateFunction,
    data,
    loading,
    error,
  };
};

export { useProductListUploadFile };
