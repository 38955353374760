import { gql, useMutation } from "@apollo/client";

type TRequest = {
  clientId: number;
};

const useProductListDelete = (
  rowList: string[],
  tableType: "product-list" | "mapping-list"
) => {
  let mutateFn;

  if (rowList.length) {
    if (tableType === "mapping-list") {
      let mutationString = "mutation RemoveProductSku {";

      rowList.forEach((item, index) => {
        const [spid, retailer] = item.split("--");
        mutationString += `
          deleteRow${index}: removeProductSku (
            retailer: "${retailer}"
            spid: "${spid}"
          )
        `;
      });
      mutationString += "}";
      mutateFn = gql(mutationString);
    } else {
      let mutationString = "mutation RemoveProductInfo {";

      rowList.forEach((item, index) => {
        mutationString += `
          deleteRow${index}: removeProductInfo (
            skuId: "${item}"
          )
        `;
      });
      mutationString += "}";
      mutateFn = gql(mutationString);
    }
  } else {
    mutateFn = gql`
      mutation ($clientId: BigInt, $date: Date!, $filters: [FilterInput!]) {
        removeProductList(
          forDate: $date
          clientId: $clientId
          filters: $filters
        )
      }
    `;
  }

  const [mutateFunction, { data, loading, error }] =
    useMutation<TRequest>(mutateFn);

  return {
    mutateFunction,
    data,
    loading,
    error,
  };
};

export { useProductListDelete };
