import React, { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";

import Calendar from "@/icons/filters/calendar.svg";
import NextYear from "@/icons/filters/next-month.svg";
import PrevYear from "@/icons/filters/prev-month.svg";
import Arrow from "@/icons/filters/arrow.svg";

import "./page-filter-calendar-monthly.scss";

type TMonth = {
  month: number;
  year: number;
  type: "active" | "in-active";
};

const chunkArray = (array: TMonth[], chunkSize: number) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
  }

  return chunkedArray;
};

dayjs.extend(localeData);

const monthOptions = [
  {
    label: "modules.atoms.filter-calendar-monthly.jan",
    value: 0,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.feb",
    value: 1,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.mar",
    value: 2,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.apr",
    value: 3,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.may",
    value: 4,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.jun",
    value: 5,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.jul",
    value: 6,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.aug",
    value: 7,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.sep",
    value: 8,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.okt",
    value: 9,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.nov",
    value: 10,
  },
  {
    label: "modules.atoms.filter-calendar-monthly.dec",
    value: 11,
  },
];

type PageFilterCalendarMonthlyProps = {
  initialDate?: {
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  };
  onSubmit: (date: {
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  }) => void;
};

const PageFilterCalendarMonthly = ({
  initialDate,
  onSubmit,
}: PageFilterCalendarMonthlyProps) => {
  const { t, i18n } = useTranslation();

  const currentDate = dayjs();
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    initialDate?.startDate || currentDate
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    initialDate?.endDate || currentDate
  );
  // const [currentMonth, setCurrentMonth] = useState(
  //   (initialDate?.endDate || currentDate).month()
  // );
  const [currentYear, setCurrentYear] = useState(
    (initialDate?.endDate || currentDate).year()
  );

  useEffect(() => {
    setStartDate(initialDate?.startDate || currentDate);
    setEndDate(initialDate?.endDate || currentDate);
  }, [initialDate]);

  const [nowSelecting, setNowSelecting] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  const monthInCurrentYear = useMemo(() => {
    const month: TMonth[] = [];

    for (let i = 9; i <= 11; i += 1) {
      month.push({
        month: i,
        year: dayjs()
          .year(currentYear - 1)
          .year(),
        type: "in-active",
      });
    }

    for (let i = 0; i <= 11; i += 1) {
      month.push({
        month: i,
        year: currentYear,
        type: "active",
      });
    }

    for (let i = 1; i <= 3; i += 1) {
      month.push({
        month: i,
        year: dayjs()
          .year(currentYear + 1)
          .year(),
        type: "in-active",
      });
    }

    return month;
  }, [currentYear]);

  const yearOptions = useMemo(() => {
    const result = [];
    for (let i = 1980; i <= currentYear; i += 1) {
      result.push({
        value: i,
        label: i,
      });
    }

    return result;
  }, []);

  const handleShowCalendar = () => setShowCalendar((prevState) => !prevState);

  const getCellClass = (item: TMonth) => {
    let current = null;
    const monthItem = dayjs().year(item.year).month(item.month);

    if (monthItem.format("MM-YYYY") === currentDate.format("MM-YYYY")) {
      current = "filter-calendar-monthly__dropdown-selected-current";
    }

    if (item.type === "in-active") {
      return [
        "filter-calendar-monthly__dropdown-selected-disabled",
        current,
      ].join(" ");
    }

    if (monthItem.format("MM-YYYY") === startDate?.format("MM-YYYY")) {
      if (startDate?.format("MM-YYYY") === endDate?.format("MM-YYYY")) {
        return ["filter-calendar-monthly__dropdown-selected", current].join(
          " "
        );
      }

      return ["filter-calendar-monthly__dropdown-selected-start", current].join(
        " "
      );
    }

    if (monthItem.format("MM-YYYY") === endDate?.format("MM-YYYY")) {
      return ["filter-calendar-monthly__dropdown-selected-end", current].join(
        " "
      );
    }

    if (monthItem.isAfter(startDate) && monthItem.isBefore(endDate)) {
      return [
        "filter-calendar-monthly__dropdown-selected-between",
        current,
      ].join(" ");
    }

    if (nowSelecting === 2 && monthItem?.isBefore(startDate)) {
      return [
        "filter-calendar-monthly__dropdown-selected-disabled",
        current,
      ].join(" ");
    }

    return ["filter-calendar-monthly__dropdown-cell", current].join(" ");
  };

  const handleClick = (item: TMonth) => () => {
    if (item.type === "in-active") {
      return undefined;
    }

    const monthItem = dayjs().year(item.year).month(item.month);

    if (nowSelecting === 2 && monthItem?.isBefore(startDate)) {
      return undefined;
    }

    if (nowSelecting === 1) {
      setStartDate(monthItem);
      setNowSelecting(2);
      setEndDate(null);
    }

    if (nowSelecting === 2) {
      setEndDate(monthItem);
      setNowSelecting(1);
    }
  };

  const handleYearSelect = (item: number) => {
    setCurrentYear(item);
  };

  const handleNextYear = () => {
    setCurrentYear((prevState) => prevState + 1);
  };

  const handlePrevYear = () => {
    setCurrentYear((prevState) => prevState - 1);
  };

  const handleSelectThisMonth = () => {
    setStartDate(currentDate.startOf("month"));
    setEndDate(currentDate.endOf("month"));
    setCurrentYear(currentDate.year());
    // setCurrentMonth(currentDate.month());
  };

  const handleSelectPrevMonth = () => {
    setStartDate(currentDate.subtract(1, "month").startOf("month"));
    setEndDate(currentDate.subtract(1, "month").endOf("month"));
    setCurrentYear(currentDate.year());
    // setCurrentMonth(currentDate.month() - 1);
  };

  const handleSelectThisYear = () => {
    setStartDate(currentDate.startOf("year"));
    setEndDate(currentDate.endOf("year"));
    setCurrentYear(currentDate.year());
    // setCurrentMonth(currentDate.month());
  };

  const handleSubmit = () => {
    if (!endDate) {
      setEndDate(startDate);
      setNowSelecting(1);
    }

    onSubmit({
      startDate,
      endDate: endDate || startDate,
    });
    setShowCalendar(false);
  };

  const handleReset = () => {
    setStartDate(initialDate?.startDate || currentDate);
    setEndDate(initialDate?.endDate || currentDate);
    // setCurrentMonth((initialDate?.endDate || currentDate).month());
    setCurrentYear((initialDate?.endDate || currentDate).year());
    setNowSelecting(1);
    handleSubmit();
  };

  const formattedStartDate = useMemo(
    () => startDate?.locale(i18n.language)?.format("MMM YY") || "",
    [i18n.language, startDate]
  );

  const formattedEndDate = useMemo(
    () => endDate?.locale(i18n.language)?.format("MMM YY") || "",
    [i18n.language, endDate]
  );

  return (
    <div className="filter-calendar-monthly">
      <div
        onClick={handleShowCalendar}
        className={`filter-calendar-monthly__input${
          showCalendar ? " filter-calendar-monthly__input--open" : ""
        }`}
      >
        <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>
        <Calendar />
      </div>
      {showCalendar && (
        <>
          <div
            className="filter-calendar-monthly__dropdown-bg"
            onClick={handleShowCalendar}
          />
          <div ref={ref} className="filter-calendar-monthly__dropdown">
            <div className="filter-calendar-monthly__dropdown-left">
              <button
                className="filter-calendar-monthly__dropdown-left__item"
                onClick={handleSelectThisMonth}
                type="button"
              >
                {t("modules.atoms.filter-calendar-monthly.this-month")}
              </button>
              <button
                className="filter-calendar-monthly__dropdown-left__item"
                onClick={handleSelectPrevMonth}
                type="button"
              >
                {t("modules.atoms.filter-calendar-monthly.last-month")}
              </button>
              <button
                className="filter-calendar-monthly__dropdown-left__item"
                onClick={handleSelectThisYear}
                type="button"
              >
                {t("modules.atoms.filter-calendar-monthly.by-year")}
              </button>
            </div>
            <div className="filter-calendar-monthly__dropdown-right">
              <div className="filter-calendar-monthly__dropdown-right-header">
                <div className="filter-calendar-monthly__dropdown-right-header-selectors">
                  <Select
                    onSelect={handleYearSelect}
                    options={yearOptions}
                    suffixIcon={<Arrow />}
                    value={currentYear}
                  />
                </div>
                <div className="filter-calendar-monthly__dropdown-right-header-switcher">
                  <button onClick={handlePrevYear} type="button">
                    <PrevYear />
                  </button>
                  <button onClick={handleNextYear} type="button">
                    <NextYear />
                  </button>
                </div>
              </div>
              <table>
                <tbody>
                  {chunkArray(monthInCurrentYear, 6).map(
                    (array, arrayIndex) => (
                      <tr key={`${arrayIndex}`}>
                        {array.map((item, index) => (
                          <td key={`${arrayIndex}-${index}`}>
                            <button
                              className={getCellClass(item)}
                              onClick={handleClick(item)}
                              type="button"
                            >
                              {t(monthOptions[item.month].label)}
                            </button>
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="filter-calendar-monthly__dropdown-right-buttons">
                <Button onClick={handleReset}>
                  {t("modules.atoms.filter-calendar-monthly.cancel")}
                </Button>
                <Button onClick={handleSubmit} type="primary">
                  {t("modules.atoms.filter-calendar-monthly.apply")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { PageFilterCalendarMonthly };
