import { gql, useQuery } from "@apollo/client";

export type TResponse = {
  getProductsSku: {
    clients: {
      clientId: string;
      title: string;
      logo: string;
    }[];
  };
};

const useProductListRetailers = () => {
  const queryFn = gql`
    query GetProductRetailers {
      clientsList: getProductRetailers {
        retailers {
          id
          title
        }
      }
    }
  `;
  const { loading, error, data } = useQuery<any>(queryFn, {
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    error,
    data,
  };
};

export { useProductListRetailers };
