import { gql, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TState } from "@/store/types";

const useExportProductsSku = () => {
  const { dynamicFilters } = useSelector((state: TState) => state.filters);

  const EXPORT_PRODUCTS_SKU = gql`
    query ExportProductsSku($filters: [FilterInput!]) {
      exportProductsSku(
        filters: $filters
        sort: { by: "CREATED_AT", order: DESC }
      )
    }
  `;

  const [getExportProductsSku, { loading, error, data }] = useLazyQuery<any>(
    EXPORT_PRODUCTS_SKU,
    {
      variables: {
        filters: getFiltersFromDynamicFilters(dynamicFilters),
      },
      fetchPolicy: "no-cache",
    }
  );

  return {
    getExportProductsSku,
    loading,
    error,
    data,
  };
};

export { useExportProductsSku };
