import React from "react";
import List from "rc-virtual-list";

interface VirtualMenuProps {
  height?: number;
  children: React.ReactNode[];
}

const VirtualMenu = React.forwardRef<any, VirtualMenuProps>(
  ({ children, height }, ref) => {
    const childrenArray = Array.isArray(children)
      ? React.Children.toArray(children)
      : [];

    // console.log(height);

    return (
      <List
        ref={ref}
        data={childrenArray.map((item, index) => index)}
        data-height={height || 300}
        itemHeight={30}
        itemKey="id"
      >
        {(index) => childrenArray[index]}
      </List>
    );
  }
);

VirtualMenu.displayName = "VirtualMenu";

export { VirtualMenu };
