import { gql, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TState } from "@/store/types";

const useExportProductsInfo = () => {
  const { dynamicFilters } = useSelector((state: TState) => state.filters);

  const EXPORT_PRODUCTS_INFO = gql`
    query ExportProductsInfo($filters: [FilterInput!]) {
      exportProductsInfo(
        filters: $filters
        sort: { by: "CREATED_AT", order: DESC }
      )
    }
  `;

  const [getExportProductsInfo, { loading, error, data }] = useLazyQuery<any>(
    EXPORT_PRODUCTS_INFO,
    {
      variables: {
        filters: getFiltersFromDynamicFilters(dynamicFilters),
      },
      fetchPolicy: "no-cache",
    }
  );

  return {
    getExportProductsInfo,
    loading,
    error,
    data,
  };
};

export { useExportProductsInfo };
