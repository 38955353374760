import { useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";

import type { TState } from "@/store/types";

export type TResponse = {
  suggest?: {
    values: {
      id: string;
      value: string;
    }[];
  };
  reviewSuggest?: {
    values: {
      id: string;
      value: string;
    }[];
  };
  // getBrandAliasesSuggest?:{
  //   values: {
  //     id: string;
  //     value: string;
  //   }[];
  // };
};

const useSuggest = (
  id: string,
  text: string,
  top: number,
  clientId: number | null,
  skip: boolean | undefined,
  page?: string
) => {
  const { date, popupDate, isPopup } = useSelector(
    (state: TState) => state.filters
  );
  const dates = {
    from: (isPopup ? popupDate : date).startDate?.format("YYYY-MM-DD") || "",
    to: (isPopup ? popupDate : date).endDate?.format("YYYY-MM-DD") || "",
  };

  let filters = "";

  if (page === "reviews") {
    filters = 'filters: [{id: "reviewType", values: ["review"]}]';
  }

  if (page === "questions") {
    filters = 'filters: [{id: "reviewType", values: ["question"]}]';
  }

  const GET_SUGGEST = gql`
    query GetSuggest(
      $id: String!
      $text: String!
      $top: Int
      ${
        page === "pdp-indexing" ||
        page === "serp-indexing" ||
        page === "brand-aliases"
          ? ""
          : "$clientId: BigInt"
      }
      ${
        page !== "brand-aliases"
          ? `$dates: ${
              page === "pdp-indexing" || page === "serp-indexing"
                ? "DateRangeInput!"
                : "DateRangeInput"
            }`
          : ""
      }
    ) {
      ${
        page === "reviews" || page === "questions"
          ? "reviewSuggest"
          : page === "pdp-indexing"
          ? "pdpIndexingStatsSuggest"
          : page === "serp-indexing"
          ? "serpIndexingStatsSuggest"
          : page === "brand-aliases"
          ? "suggest: getBrandAliasesSuggest"
          : "suggest"
      }(
        id: $id
        text: $text
        top: $top
        ${
          page === "pdp-indexing" ||
          page === "serp-indexing" ||
          page === "brand-aliases"
            ? ""
            : "clientId: $clientId"
        }
        ${page !== "brand-aliases" ? "dates: $dates" : ""}
        ${filters}
      ) {
        values {
          id
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<TResponse>(GET_SUGGEST, {
    variables: {
      id,
      text,
      top,
      clientId,
      dates,
    },
    skip: !skip || !text,
  });

  return { loading, error, data };
};

export { useSuggest };
